import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthGuardService } from '../services/auth-guard.services';

@Injectable({providedIn: 'root'})
export class AuthGuard  {

  constructor(private router: Router, private authGuardService: AuthGuardService) {}

  public canActivate(): Observable<boolean> {
    return this.authGuardService.isAuthenticated()
      .pipe(map((loggedIn: boolean) => {
          if (!loggedIn) {
            this.router.navigate(['/login']);
          }
          return loggedIn;
        })
      );
  }

}
