import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({ providedIn: 'root' })
export class AuthGuardService {

  public isAuthenticated(): Observable<boolean> {

    const localToken = localStorage.getItem('token');
    const helper = new JwtHelperService();
    return !localToken ? of(false) : of(!helper.isTokenExpired(localToken));
  }

}


